import { createContext, useContext, useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getAllUsers,
  getUsersById,
  postUsers,
  updateUsers,
  deleteUsersById
} from "../services/users/users-service.js";
import { getUserProfile } from "../services/auth/auth-service.js";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const profileData = JSON.parse(localStorage.getItem("profile"));
  const [authUserProfile, setAuthUserProfile] = useState(profileData);

  useEffect(() => {
    fetchUserProfile();
  },[]);

  const location = useLocation();

  const navigate = useNavigate();

  const getUserLoggedInUserDataQuery = useQuery(
    ["user", userId],
    () => getUsersById(userId),
    {
      enabled: userId ? true : false,
      onSuccess: (data) => {
        setUserData(data?.data);
        console.log("auth context users-by-id : ", data);
      },
      onError: (error) => {
        toast.error(
          "There was an error getting user data ! Please contact the administrator"
        );

        console.log("Error:", error.message);
      }
    }
  );

  const fetchUserProfile = () => {
        let userProfile = getUserProfile()
        setAuthUserProfile(userProfile)
    }

  return (
    <AuthContext.Provider
      value={{
        getUserLoggedInUserDataQuery,
        setUserId,
        authUserProfile,
        setAuthUserProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuthContext() {
  return useContext(AuthContext);
}
