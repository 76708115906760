import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { Alert, Table } from 'react-bootstrap'
import { getHotKeywords } from '../../../services/finance/payments-service';
import { formatNumber } from '../../../utils/helper_functions';

function HotKeywords() {
    const { isLoading, isError, error, data:keywords, isFetching, isPreviousData } =
    useQuery({
      queryKey: ["hot-keywords"],
      queryFn: () => getHotKeywords({}),
      keepPreviousData: true
    });
  return (
    <div>
         <Table responsive>
                <thead>
                  <tr>
                    <td></td>
                    <td>Hits</td>
                    <td>
                      Amount (<small>ugx</small>)
                    </td>
                  </tr>
                </thead>
                <tbody>
                {isLoading ? (<tr><td colSpan={3}>Loading</td></tr>) : isError ? (
                    <><tr><td colSpan={3}><Alert>Error: {error.message}</Alert></td></tr></>
                ) : (<>
                        {keywords.data.map((item, i)=>(
                            <tr key={i}>
                            <th>{item.keyword}</th>
                            <td>{item.count}</td>
                            <td>{formatNumber(item.total)}</td>
                        </tr>
                        ))}
                </>)}
                 </tbody> 
              </Table>
    </div>
  )
}

export default HotKeywords