import React, { useContext, useState } from "react";
import {
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Spinner
} from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { getUserProfile, logout } from "../../services/auth/auth-service";
import AuthDetailContext from "../../context/AuthDetailContext";
import { Modal, Button, Form} from "react-bootstrap";
import { changePasswordPost } from "../../services/users/users-service.js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import useAuthContext from "../../context/AuthContext.js";

function TopNavBar() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
   const {
      authUserProfile,
    } = useAuthContext();

  const logoutUser = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      await logout();
      //navigate("/login", { replace: true });
      window.location.reload(true);
    }
  };

  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const handleChangePasswordModalClose = () => {
    setNewPassword();
    setOldPassword();
    setValidationErrors([]);
    setShowResetPasswordModal(false);
  };

  const handleResetPasswordModalShow = () => {
    setShowResetPasswordModal(true);
  };

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvasClose = () => {
    setShowOffcanvas(false);
  };

  const handleOffcanvasShow = () => {
    setShowOffcanvas(true);
  };

  const handleNavItemClick = () => {
    // Close the offcanvas when a navigation link or item is clicked
    handleOffcanvasClose();
  };

  //password resset

  const [validationErrors, setValidationErrors] = useState([]);

  const userChangePasswordMutation = useMutation(changePasswordPost, {
    onSuccess: () => {
      queryClient.resetQueries(["users"]);
      // queryClient.invalidateQueries(["projects"]);
      toast.success("Password Changed Successfully");
    },
    onError: (error) => {
      if (error.response) {
        const errorData = error.response.data;
        console.log("Validation errors:", errorData);

        const testvalidationErrors = Object.entries(errorData).map(
          ([key, value]) => ({
            key,
            value: value.join(", ") // Convert the array of error messages to a string
          })
        );

        setValidationErrors(testvalidationErrors);

        // Create the alert message
        const alertMessage = (
          <ul>
            {testvalidationErrors.map((error, index) => (
              <li key={index}>
                {error.key} : {error.value}
              </li>
            ))}
          </ul>
        );

        // Show the alert using your preferred alert mechanism (e.g., toast)
        toast.warning(alertMessage);
      } else {
        toast.error(
          "There was an error while changing password ! Please contact the administrator"
        );
      }
    }
  });

  const [newPassword, setNewPassword] = useState();
  const [oldPassword, setOldPassword] = useState();

  const handleChangePassword = () => {
    // Implement the reset password logic here
    if (!newPassword || !oldPassword) {
      toast.warning("All fields are required");
    } else {
      let item = { new_password: newPassword, old_password: oldPassword };
      userChangePasswordMutation.mutate(item);
      //handleChangePasswordModalClose();
    }
  };

  //password eye toggler
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  

  return (
    <>
      <Navbar bg="success" data-bs-theme="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">{process.env.REACT_APP_NAME}</Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-1`}
            onClick={handleOffcanvasShow}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-1`}
            aria-labelledby={`offcanvasNavbarLabel-expand-1`}
            placement="end"
            show={showOffcanvas}
            onHide={handleOffcanvasClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-1`}>
                MENU
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="me-auto">
                <Nav.Link as={NavLink} to="/" onClick={handleNavItemClick}>
                  Dashboard
                </Nav.Link>

                {/* ==============   Not staff start========== */}
                {!authUserProfile?.is_staff && (
                  <>
                    <Nav.Link
                      as={NavLink}
                      to="/keywords"
                      onClick={handleNavItemClick}
                    >
                      Keywords
                    </Nav.Link>

                    <Nav.Link
                      as={NavLink}
                      to="/content"
                      onClick={handleNavItemClick}
                    >
                      Content
                    </Nav.Link>

                    <Nav.Link
                      as={NavLink}
                      to="/messages"
                      onClick={handleNavItemClick}
                    >
                      Outbox
                    </Nav.Link>

                    <Nav.Link
                      as={NavLink}
                      to="/Payments"
                      onClick={handleNavItemClick}
                    >
                      Payments
                    </Nav.Link>
                    <Nav.Link
                        as={NavLink}
                        to="/pocket/detail"
                        onClick={handleNavItemClick}
                      >
                        Wallet
                      </Nav.Link>
                  </>
                )}

                {/* ==============   Not staff end ========== */}

                {/* =============== staff ============== */}
                
                {authUserProfile?.is_staff && (
                  <>
                    {/* Content drop down */}
                    <Nav.Link
                        as={NavLink}
                        to="/Payments"
                        onClick={handleNavItemClick}
                      >
                        Payments
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        to="/messages"
                        onClick={handleNavItemClick}
                      >
                        Messages
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        to="/content"
                        onClick={handleNavItemClick}
                      >
                        Content
                      </Nav.Link>
                    
                      <Nav.Link
                        as={NavLink}
                        to="/channels/sessions"
                        onClick={handleNavItemClick}
                      >
                        Sessions
                      </Nav.Link>
                 
                    <Nav.Link
                        as={NavLink}
                        to="/wallet"
                        onClick={handleNavItemClick}
                      >
                        Wallets
                      </Nav.Link>
                    {/* Channels drop down */}
                    <NavDropdown title="Settings" id="basic-nav-dropdown">
                        <NavDropdown.Item
                            as={NavLink}
                            to="/channels/menus"
                            onClick={handleNavItemClick}
                          >
                            Menus
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            as={NavLink}
                            to="/keywords"
                            onClick={handleNavItemClick}
                          >
                            Keywords
                      </NavDropdown.Item>
                          <NavDropdown.Item
                            as={NavLink}
                            to="/accounts"
                            onClick={handleNavItemClick}
                          >
                            User Accounts
                          </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Marketing" id="basic-nav-dropdown">
                        <NavDropdown.Item
                            as={NavLink}
                            to="/broadcasts"
                            onClick={handleNavItemClick}
                          >
                             Broadcasts
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            as={NavLink}
                            to="/blasts"
                            onClick={handleNavItemClick}
                          >
                             Out Blasts
                          </NavDropdown.Item>
                    </NavDropdown>
                    
                     
                  </>
                )}

               
              </Nav>

              <Nav>
              
                <NavDropdown
                  title={`Hi, ${authUserProfile?.first_name} ${authUserProfile?.last_name}`}
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item
                    href="#"
                    onClick={handleResetPasswordModalShow}
                  >
                    Change Password
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/accounts/profile"
                    state={{ userId: authUserProfile?.id }}
                    className="text-decoration-none"
                    onClick={handleNavItemClick}
                  >
                    Profile
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => logoutUser()}>
                    Sign Out
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {/* Reset Password Modal */}
      <Modal
        show={showResetPasswordModal}
        onHide={handleChangePasswordModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {validationErrors.length > 0 && (
            <div className="alert alert-danger">
              <ul>
                {validationErrors.map((error, index) => (
                  <li key={index}>
                    {error.key} : {error.value}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <Form>
            <Form.Group controlId="confirmPassword">
              <Form.Label>Old Password</Form.Label>
              <div className="password-input">
                <Form.Control
                  type={showOldPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder="Enter old password"
                />
                <div
                  className="password-toggle"
                  onClick={toggleShowOldPassword}
                >
                  {showOldPassword ? <EyeSlash /> : <Eye />}
                </div>
              </div>
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>New Password</Form.Label>
              <div className="password-input">
                <Form.Control
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter new password"
                />
                <div
                  className="password-toggle"
                  onClick={toggleShowNewPassword}
                >
                  {showNewPassword ? <EyeSlash /> : <Eye />}
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleChangePasswordModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleChangePassword}>
            Change Password
          </Button>
        </Modal.Footer>
        {userChangePasswordMutation.isLoading && (
          <center>
            {" "}
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </center>
        )}
      </Modal>
    </>
  );
}

export default TopNavBar;
