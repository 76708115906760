import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import TopNavBar from "./TopNavBar";

import useAuthContext, { AuthProvider } from "../../context/AuthContext";
import { getUserProfile } from "../../services/auth/auth-service";

function UserLayout({ children }) {
  const [profile, setProfile] = useState()
  useEffect(() => {
    fetchUserProfile()
  },[])
  const fetchUserProfile = () => {
        let userProfile = getUserProfile()
        setProfile(userProfile)
    }

  return (
    <div>
      
      <TopNavBar />
      <Container>
        <Row className="g-3">
          <Col xs={12} className="pt-3">
            {children}
          </Col>
        </Row>
      </Container>
     
    </div>
  );
}

export default UserLayout;
