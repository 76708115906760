import React, { useState } from 'react'
import LineGraphSummary from './LineGraphSummary'
import { useQuery } from '@tanstack/react-query';
import { getWeeklyHitsSummary } from '../../../services/finance/payments-service';
import { Alert, Spinner } from 'react-bootstrap';

function WeeklyGraphWidget() {
    const [labels, setLabels] = useState([])
    const [data, setData] = useState([])
    const { isLoading, isError, error, data:hits, isFetching, isPreviousData } =
        useQuery({
            queryKey: ["finance", "hitsummary"],
            queryFn: () => getWeeklyHitsSummary(),
            onSuccess: (response) => {
                let labels_list = []
                let value_list = []
                
                let data = response.data
                for(const item in data){
                    // console.log(data[item])
                    labels_list.push(data[item].label)
                    value_list.push(data[item].total)
                }
                setLabels(labels_list)
                setData(value_list)
            },
            refetchInterval: 120 * 1000,
            refetchIntervalInBackground: false
    });

  return (
    <div>
        {isLoading ? (
            <Spinner />
          ) : isError ? (
            <Alert variant='danger'>Error: {error.message}</Alert>
          ) : (
                <LineGraphSummary labels={labels} data={data} />
          )}
    </div>
  )
}

export default WeeklyGraphWidget